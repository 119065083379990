<style lang="sass" scoped>
.table-item-container
  &:hover
    .table-item
      opacity: 0.3
      &:hover
        opacity: 1
        // background-color: #fdffb6

</style>
<template lang="pug">
div(style='margin-left: 70px')
  //- pre {{$store.state.status_flows}}
  //- pre {{$store.state.tags}}
  router-view(v-if='$store.state.status_flows')

</template>

<script>

export default {
  name: 'index',
  props: ['property_id', 'sf_id'],
  components: {

  },
  computed: {

  },
  async mounted() {

  },
  watch: {

  },
  computed: {

  },
  data() {
    return {
      // status_flows_loaded: () => { return this.status_flows },
    }
  },
  async mounted() {
    await this.$store.dispatch('status flows', this.property_id)
    this.$store.dispatch('users by id', this.property_id)
    this.$store.dispatch('message templates', this.property_id)

    // this.status_flows_loaded = this.status_flows
  },
  methods: {
    get_status_flows() {
      return this.status_flows
    }
  },
}
</script>
